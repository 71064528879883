<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">{{ $t(title) }}</h4>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="float-end d-none d-md-block">
          <router-link to="/users/individuals">
            <button class="btn btn-primary">
              {{ $t('back') }}
              <i class="mdi mdi-keyboard-backspace"></i>
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form id="add-company-form" class="needs-validation" @submit.prevent="tooltipForm">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="">لقب المستخدم</label>
                    <select v-model="tooltipform.title" class="form-control" name="title" required>
                      <option value="mr">{{ $t('mr') }}</option>
                      <option value="ms">{{ $t('ms') }}</option>
                      <option value="miss">{{ $t('miss') }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-3 position-relative">
                    <label class="form-label" for="validationTooltip01">الأسم الأول</label>
                    <input id="validationTooltip01" v-model="tooltipform.fname" type="text" class="form-control"
                      placeholder="الأسم الأول" :class="{
                        'is-invalid': submitform && $v.tooltipform.fname.$error,
                      }" />
                    <div v-if="submitform && $v.tooltipform.fname.$error" class="invalid-tooltip">
                      <span v-if="!$v.tooltipform.fname.required">
                        الاسم الاول مطلوب.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="mb-3 position-relative">
                    <label class="form-label" for="validationTooltip01">الاسم الاخير</label>
                    <input id="validationTooltip01" v-model="tooltipform.lname" type="text" class="form-control"
                      placeholder="الاسم الاخير" :class="{
                        'is-invalid': submitform && $v.tooltipform.lname.$error,
                      }" />
                    <div v-if="submitform && $v.tooltipform.lname.$error" class="invalid-tooltip">
                      <span v-if="!$v.tooltipform.lname.required">
                        الاسم الاخير مطلوب.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-1 col-4" style="position: relative;">
                  <label for="">رقم الجوال</label>
                  <div class="btn btn-default"
                    style="width: 100%; height:  2.375rem;border: 1px solid rgba(120, 130, 140, .2);border-radius: 4px;direction: ltr;">
                    <span class="filter-option current pull-left">
                      <div class="cc-con">
                        <div class="cc-flag" style="position: absolute;margin: auto;left: 0;right:0;">
                          <span class="flag flag-sa"></span>
                          <span class="ml10 mr10 code">+966</span>
                        </div>
                      </div>
                    </span>&nbsp;
                  </div>
                  <div role="combobox" class="dropdown-menu open"
                    style="display: none; height: 280px; overflow: hidden; min-height: 179px; min-width: 250px !important; max-width: 250px !important;">
                    <div class="bs-searchbox" style="padding: 4px 8px;">
                      <input type="text" autocomplete="off" role="textbox" aria-label="Search" class="form-control"
                        style="width: 100%; border-bottom: 1px solid #cccccc; border-radius: 0px; margin-bottom: 13px;display: inline-block;">
                    </div>
                    <ul role="listbox" aria-expanded="true" class="dropdown-menu country-code-sign"
                      style="max-height: 292.243px;overflow-y: auto;min-height: 122px;top: 48px;width: 100%;border: navajowhite;box-shadow: none;direction: ltr;">
                      <li v-for="code in CountryCodeDetails" :key="code.code" data-original-index="0" style="padding: 0;">
                        <a href="javascript:void(0);" :data-code="code.code" :data-dial-code="code.dial_code"
                          style="display: block;padding: 15px 20px 15px 20px;width: 100%;">
                          <div class="cc-con"
                            style="flex-direction: row-reverse!important;display: flex;flex-wrap: wrap;justify-content: space-between;align-items: center;">
                            <div class="cc-flag">
                              <span class="ml10 mr10">
                                {{ code.dial_code }}
                                <span :class="'flag flag-' + (code.code.toLowerCase())"></span>
                              </span>
                            </div>
                            <span class="location"
                              style="font-size: 12px;color: #909090;max-width: 100px;text-overflow: ellipsis;white-space: nowrap;text-align: right;overflow: hidden;">
                              {{ code.name }}
                            </span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <input v-model="tooltipform.country_code" type="hidden" class="mobile-code" name="country_code"
                    value="+966">
                </div>
                <div class="col-md-5 col-8">
                  <div class="mb-3 position-relative">
                    <label class="form-label" for="validationTooltip01" style="visibility: hidden;">رقم الجوال</label>
                    <input id="validationTooltip01" v-model="tooltipform.phone" type="text" maxlength="10"
                      class="form-control" placeholder="رقم الجوال" :class="{
                        'is-invalid': submitform && $v.tooltipform.phone.$error,
                      }" />
                    <div v-if="submitform && $v.tooltipform.phone.$error" class="invalid-tooltip">
                      <span v-if="!$v.tooltipform.phone.required">
                        رقم الجوال مطلوب.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="mb-3 position-relative">
                    <label class="form-label" for="validationTooltip01">
                      البريد الالكتروني
                    </label>
                    <input id="validationTooltip01" v-model="tooltipform.email" type="text" class="form-control"
                      placeholder="البريد الالكتروني" :class="{
                        'is-invalid': submitform && $v.tooltipform.email.$error,
                      }" />
                    <div v-if="submitform && $v.tooltipform.email.$error" class="invalid-tooltip">
                      <span v-if="!$v.tooltipform.email.required">
                        البريد الالكتروني مطلوب.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary float-right mt-3" type="submit">
                {{ $t('add') }}
                <i class="mdi mdi-plus-thick"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
var that;
import $ from 'jquery';
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import countryCode from '@/assets/file/country-code.json';
import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";

export default {
  page: {
    title: "Add a user",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout },
  data() {
    return {
      title: "Add a user",
      CountryCodeDetails: [],
      tooltipform: {
        title: "mr",
        fname: "",
        lname: "",
        email: "",
        country_code: "+966",
        phone: "",
      },
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    tooltipform: {
      title: { required },
      fname: { required },
      lname: { required },
      email: { required },
      country_code: { required },
      phone: { required }
    },
  },
  methods: {
    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
    },
    Jquery() {
      $(document).on('submit', '#add-company-form', function () {
        if (!that.$v.$anyError) {
          $(this).find(":submit").attr('disabled', 'disabled');
          let form = new FormData();
          form.append('title', that.tooltipform.title);
          form.append('fname', that.tooltipform.fname);
          form.append('lname', that.tooltipform.lname);
          form.append('email', that.tooltipform.email);
          form.append('country_code', that.tooltipform.country_code);
          form.append('phone', that.tooltipform.phone);
          form.append('lang', that.$i18n.locale);
          const headers = {
            'Authorization': `Bearer ${localStorage.getItem("access_token")}`,
          };
          var url = `${that.$appUrl}/api/admin/users/store`;
          that.axios.post(url, form, { headers })
            .then(response => {
              if (response.data.status == 1) {
                that.$toast.open({
                  message: response.data.message,
                  type: 'success',
                  position: 'top-left',
                  duration: 2500
                });
                setTimeout(() => {
                  that.$router.push(`/users/individuals`);
                }, 1000);
              } else {
                $(this).find(":submit").prop('disabled', false);
                that.$toast.open({
                  message: response.data.message,
                  type: 'error',
                  position: 'top-left',
                  duration: 2500
                });
              }
            });
        }
      });
      $(document).on('click', '.country-code-sign li a', function () {
        var code = $(this).data('code'),
          dial_code = $(this).data('dial-code'),
          flag = $('.filter-option.current .flag');
        $('.filter-option.current .flag').removeClass();
        $('.dropdown-menu.open').hide();
        $('.mobile-code').val(dial_code);
        that.tooltipform.country_code = dial_code;
        $('.filter-option.current .code').html(dial_code);
        flag.addClass('flag flag-' + code.toLowerCase());
        that.CountryCodeDetails = countryCode;
      });
      function scrollToElementWithCode(code) {
        var targetElement = $('[data-code="' + code + '"]');
        if (targetElement.length > 0) {
          var offsetTop = targetElement.offset().top;
          $('.country-code').animate({ scrollTop: offsetTop + 80 }, 'slow');
        }
      }
      $(document).on('click', '.filter-option', function () {
        scrollToElementWithCode($('.mobile-code').val());
        $('.dropdown-menu.open').show();
      });
      $(document).on('input', '.bs-searchbox input', function () {
        var val = $(this).val();
        that.CountryCodeDetails = Object.values(countryCode).filter(item => {
          return (that.$i18n.locale == 'ar' ? item.name.includes(val) : item.en_name.includes(val)) || item.dial_code.includes(val);
        });
      });
    }
  },
  mounted() {
    that = this;
    this.Jquery();
    this.CountryCodeDetails = countryCode;
  }
};
</script>
<style lang="scss" scoped>
.image-container {
  border: 2px dashed #e0e0e0;
  border-radius: 3px;
  width: 100%;
  margin-top: 2%;
  min-height: 150px;
  cursor: pointer;

  img {
    display: block;
    margin: 0px auto;
    max-width: 385px;
    padding: 30px 0px;
  }
}

label {
  max-width: 100%;
  margin-bottom: 5px;
  padding-top: 5px;
  font-weight: 600;
}

.open .dropdown-menu {
  display: block;
}

.dropdown-menu {
  position: absolute;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 100px;
  padding: 0px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

li {
  display: flex;
}

.dropdown-menu>li>a {
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #000 !important;
  white-space: nowrap;
}

.country-code-sign::-webkit-scrollbar {
  width: 3px;
}

.country-code-sign::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e3e3e3;
  border-radius: 25px;
}

.country-code-sign::-webkit-scrollbar-thumb {
  background: #196dfb;
  border-radius: 25px;
}

.country-code-sign::-webkit-scrollbar-thumb:hover {
  background: #196dfb;
}

.code {
  padding-left: 4px;
  vertical-align: text-bottom;
}
input[type="number"],
input[type="date"],
input[type="text"] {
  border-color: rgba(120, 130, 140, .2);
  border-radius: 0;
  min-height: 2.375rem;
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}
</style>
<style>
.md-table.md-theme-default .md-table-row td {
  text-align: center;
}

.md-table.md-theme-default .md-table-head {
  text-align: center;
}
</style>